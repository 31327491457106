<template>
  <div>
    <el-dialog
    v-dialogDrag
      title="新增基差挂单"
      :close-on-click-modal="false"
      :visible.sync="dialogVisible"
      :before-close="handleClose"
      width="900px"
    >
      <div>
        <el-form ref="form" :rules="rules" :model="form" label-position="left">
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item label="商品分类" prop="commodityId" label-width="120px">
                <el-cascader
                  class="width-100"
                  v-model="form.commodityId"
                  :options="categoryOptions"
                  :props="categoryProps"
                  :show-all-levels="false"
                ></el-cascader>
              </el-form-item>
            </el-col>
            <el-col :span="12" v-for="(item,index) of gradeIdOptions" :key="index">
              <el-form-item label="商品属性" prop="specificationsName" label-width="120px">
                <el-select class="width-100" v-model="form.specificationsName[index]">
                  <el-option
                    v-for="items in item.children"
                    :key="items.id"
                    :label="items.value"
                    :value="item.value+','+items.value"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="数量" prop="quantity" label-width="120px">
                <el-input v-model.number="form.quantity"></el-input>
              </el-form-item>
            </el-col>

            <el-col :span="12">
              <el-form-item label="基差价" prop="basisPrice" label-width="120px">
                <el-input v-model="form.basisPrice"></el-input>
              </el-form-item>
            </el-col>
            <!-- <el-col :span="12">
              <el-form-item label="总价" prop="zjPriceCalc" label-width="120px">
                <el-input v-model.number="zjPriceCalc" disabled></el-input>
              </el-form-item>
            </el-col>-->
            <el-col :span="12">
              <el-form-item label="重量" prop="weight" label-width="120px">
                <el-input v-model="form.weight"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="重量单位" prop="weightUnitId" label-width="120px">
                <el-select class="width-100" v-model="form.weightUnitId">
                  <el-option
                    v-for="items in weightUnitOptions"
                    :key="items.weightUnitId"
                    :label="items.name"
                    :value="items.weightUnitId"
                  ></el-option>
                </el-select>
                <!-- <el-input v-model="form.unit"></el-input> -->
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="厂家" prop="manufacturer" label-width="120px">
                <el-input v-model="form.manufacturer"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="仓库" prop="storageId" label-width="120px">
                <el-select class="width-100" v-model="form.storageId">
                  <el-option
                    v-for="item in storageIdOptions"
                    :key="item.storageId"
                    :label="item.name"
                    :value="item.storageId"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="最后点价日" prop="somePriceTime" label-width="120px">
                <el-date-picker
                  class="width-100"
                  v-model="form.somePriceTime"
                  type="date"
                  :picker-options="putWarehousTimePicker"
                ></el-date-picker>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="初始交收日" prop="deliveryTime" label-width="120px">
                <el-date-picker
                  class="width-100"
                  v-model="form.deliveryTime"
                  type="date"
                  :picker-options="putWarehousTimePicker"
                ></el-date-picker>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="是否接受议价" prop="bargainingStatus" label-width="120px">
                <el-radio-group v-model="form.bargainingStatus" size="small">
                  <el-radio label="T">接受</el-radio>
                  <el-radio label="F">不接受</el-radio>
                </el-radio-group>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="业务负责人" prop="businessName" label-width="120px">
                <el-input v-model="form.businessName"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="联系电话" prop="businessPhone" label-width="120px">
                <el-input v-model="form.businessPhone"></el-input>
              </el-form-item>
            </el-col>

            <el-col :span="12">
              <el-form-item label="交易方式" prop="tradeWay" label-width="120px">
                <el-radio-group v-model="form.tradeWay" size="small">
                  <el-radio label="A">整批</el-radio>
                  <el-radio label="P">分销</el-radio>
                </el-radio-group>
              </el-form-item>
            </el-col>
            <!-- <el-col :span="12">
              <el-form-item label="商品图片" prop="image" label-width="120px">
                <el-upload
                  class="avatar-uploader"
                  :action="actionUrl"
                  :show-file-list="false"
                  :on-success="handleAvatarSuccess"
                  :before-upload="beforeAvatarUpload"
                  :data="postData"
                >
                  <img v-if="form.image" :src="form.image" class="avatar" />
                  <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                </el-upload>
              </el-form-item>
            </el-col>-->
          </el-row>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="handleClose">取 消</el-button>
        <el-button type="primary" @click="confirm()">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
export default {
  data() {
    var checkAmount = (rule, value, callback) => {
      let reg = /^[1-9]\d*$/g;
      //  if(value){
      //   if (!reg.test(value)) {
      //   return callback(new Error("请输入正整数"));
      // } else {
      //   callback();
      // }
      // }else{
      //     callback()
      // }
      if (value == "") {
        callback(new Error("请输入数量"));
      } else if (!reg.test(value)) {
        return callback(new Error("请输入正整数"));
      } else {
        callback();
      }
    };
    var checkLowWight = (rule, value, callback) => {
      let reg = /^\d+(\.\d{0,2})?$/;
      if (value == "") {
        return callback(new Error("请输入重量"));
      } else if (!reg.test(value) || Number(value) <= 0) {
        return callback(new Error("重量必须是正数且不能超过两位小数"));
      } else {
        callback();
      }
    };
    var checkPutHoldWeight = (rule, value, callback) => {
      let reg = /^\d+(\.\d{0,2})?$/;
      if (value == "") {
        return callback(new Error("请输入重量"));
      } else if (!reg.test(value) || Number(value) <= 0) {
        return callback(new Error("重量必须是正数且不能超过两位小数"));
      } else {
        callback();
      }
    };
    var checkSpecName = (rule, value, callback) => {
      if (
        this.gradeIdOptions.length != 0 &&
        value.length != this.gradeIdOptions.length
      ) {
        return callback(new Error("请选择商品属性"));
      } else {
        callback();
      }
    };
    return {
      dialogVisible1: false,
      dialogVisible: false,
      gradeName: null,
      lowWeight: null,
      hightWeight: null,
      stepPrice: null,
      stepPriceList: [],
      right: {
        addStepPrice: false
      },
      form: {
        bargainingStatus: null, //是否可以议价T：可以F：不可以
        basisPrice: null, //基差价
        businessName: null, //业务负责人
        businessPhone: null, //
        commodityId: null, //
        deliveryTime: null, //初始交收日
        holdId: null, //持仓id
        manufacturer: null, //厂家
        quantity: null, //挂单数量
        somePriceTime: null, //点价日
        sourceId: null, //来源M：基差挂单F：转让挂单
        specificationsName: [], //
        storageId: null, //仓库id
        tradeWay: null, //A：整批P：分销
        treatyId: 1, //合约id
        weight: null, //挂单重量
        weightUnitId: null //挂单重量单位
      },
      newForm: {
        code: null,
        marginPassWord: null
      },
      weightUnitOptions: [],
      selectWeightUnit: {},

      rules: {
        commodityId: [
          { required: true, message: "请选择商品分类", trigger: "change" }
        ],
        specificationsName: [
          { required: false, message: "请选择商品属性", trigger: "change" },
          { validator: checkSpecName, trigger: "blur" }
        ],
        basisPrice: [
          { required: true, message: "请输入基差价", trigger: "change" },
          { validator: checkLowWight, trigger: "change" }
        ],
        bargainingStatus: [
          { required: true, message: "请选择是否可议价", trigger: "change" }
        ],
        deliveryTime: [
          { required: true, message: "请选择初始交收日", trigger: "change" }
        ],
        somePriceTime: [
          { required: true, message: "请选择点价日", trigger: "change" }
          // { validator: checkOpenTicketDay, trigger: "blur" }
        ],

        quantity: [
          { required: true, message: "请输入数量", trigger: "change" },
          { validator: checkAmount, trigger: "change" }
        ],
        weight: [
          { required: true, message: "请输入挂单重量", trigger: "change" },
          {
            validator: checkPutHoldWeight,
            trigger: "change"
          }
        ],
        weightUnitId: [
          { required: true, message: "请选择重量单位", trigger: "change" }
        ],
        // image: [
        //   { required: true, message: "请上传图片", trigger: "change" }
        // ],
        manufacturer: [
          { required: false, message: "请输入生产厂家", trigger: "change" }
        ],
        storageId: [
          { required: true, message: "请选择仓库", trigger: "change" }
        ],
        tradeWay: [
          { required: true, message: "请选择交易方式", trigger: "change" }
        ],
        businessName: [
          { required: true, message: "请输入业务负责人", trigger: "change" }
        ],
        businessPhone: [
          { required: true, message: "请输入联系电话", trigger: "change" },
          // {
          //   pattern: /^1[3456789]\d{9}$/,
          //   message: "手机号格式不正确",
          //   trigger: "blur"
          // }
        ]
      },

      putWarehousTimePicker: {
        disabledDate: time => {
          return time.getTime() < Date.now() - 8.64e7;
        }
      },
      categoryProps: {
        label: "className",
        value: "classId"
      },
      gradeIdProps: {
        label: "value",
        value: "value"
      },
      categoryOptions: [],
      gradeIdOptions: [],
      packagIdOptions: [],
      specificationsIdOptions: [],
      storageIdOptions: [],
      // zjPriceCalc: null,
      isAddLoading: false,
      actionUrl: window.g.APP_USER_PATH_UPLOAD,
      postData: {
        uid: this.$store.state.common.sessionInfo.firmId,
        deleteStatus: 0,
        width: null,
        height: null,
        size: null
      },
      validButtonText: "发送验证码",
      validBtnDisabled: false,
      maxWeight: null,
      newCommodityId: null
    };
  },
  watch: {
    "form.commodityId": {
      handler(val, oldVal) {
        this.getGradeIdOptions();
      }
    },
    "form.weightUnitId": {
      handler(val, oldVal) {
        let that = this;
        for (let i = 0; i < this.weightUnitOptions.length; i++) {
          if (val == that.weightUnitOptions[i].weightUnitId) {
            that.selectWeightUnit = that.weightUnitOptions[i];
          }
        }
      }
    }
  },
  computed: {
    ...mapGetters(["getMarginRateGetter"]),
    deposit() {
      let currentPrice = util.floatMul(this.form.price, this.form.weight);
      return util.floatMul(currentPrice, this.getMarginRateGetter.marginRate);
    },
    zjPriceCalc() {
      return util.floatMul(this.form.price, this.form.weight);
    }
  },
  methods: {
    handledialogVisible1() {
      this.dialogVisible1 = false;
    },
    showDialog() {
      this.dialogVisible = true;
      this.getCommodityCategory();
      this.getfindWeightUnit();
      this.getStorageIdOptions();
    },

    getfindWeightUnit() {
      http.postFront(protocolFwd.param_findWeightUnit).then(response => {
        const { code, message, value } = response.data;
        if (code == 0) {
          this.weightUnitOptions = value.content;
        } else {
          this.$EL_MESSAGE(message);
        }
      });
    },
    addPrice() {
      const that = this;
      let obj = {};
      obj.lowWeight = this.lowWeight;
      obj.highWeight = this.hightWeight;
      obj.price = this.stepPrice;
      this.stepPriceList.push(obj);
      this.centerDialogVisible = false;
      this.lowWeight = null;
      this.hightWeight = null;
      this.stepPrice = null;
    },
    setTime(e, vueThis) {
      let _this = this;
      e--;
      vueThis.validButtonText = e + "s";
      if (e > 0) {
        setTimeout(function() {
          _this.setTime(e, vueThis);
        }, 1000);
      } else {
        vueThis.validButtonText = "发送验证码";
        vueThis.validBtnDisabled = false;
      }
    },
    modifyStepPrice(item, index) {
      // 修改阶梯价
      this.lowWeight = item.lowWeight;
      this.hightWeight = item.highWeight;
      this.stepPrice = item.price;
      this.centerDialogVisible = true;
      this.stepPriceList.splice(index, 1);
    },
    sureStepPrice(value) {
      protocolFwd.param_setPhaseHangResourcePrice.param.hangResourcesId = value;
      protocolFwd.param_setPhaseHangResourcePrice.param.obj = this.stepPriceList;
      http
        .postFront(protocolFwd.param_setPhaseHangResourcePrice)
        .then(response => {
          this.$EL_MESSAGE(response.data.message);
          this.dialogVisible = false;
          this.dialogVisible1 = false;
          this.$EL_MESSAGE("提交成功！待后台审核成功后方可发布");
          this.$refs["form"].resetFields();
          this.$emit("reLoad");
        });
    },
    sendValidNo() {
      protocol.param_authn_margin_sendCaptcha.param.id = 30;
      http.postFront(protocol.param_authn_margin_sendCaptcha).then(response => {
        this.$EL_MESSAGE(response.data.message);
        const that = this;
        this.validBtnDisabled = true;
        this.setTime(60, that);
      });
    },
    getCommodityCategory() {
      http.getRes(protocolFwd.param_queryVarietiesThree).then(response => {
        const { code, message, value } = response.data;
        if (code == 0) {
          this.deleteCategoryOptionsLast(value);
          this.categoryOptions = value;
        } else {
          this.$EL_MESSAGE(message);
        }
      });
    },
    deleteCategoryOptionsLast(data) {
      for (const item of data) {
        if (item.children.length !== 0) {
          this.deleteCategoryOptionsLast(item.children);
        } else {
          delete item.children;
        }
      }
    },
    getGradeIdOptions() {
      if (Array.isArray(this.form.commodityId)) {
        this.newCommodityId = this.form.commodityId[
          this.form.commodityId.length - 1
        ];
      } else {
        this.newCommodityId = this.form.commodityId;
      }
      protocolFwd.param_queryTemplateGroup.param.goodsGroupClassId = this.newCommodityId;
      http.postFront(protocolFwd.param_queryTemplateGroup).then(response => {
        const { code, message, value } = response.data;
        if (code == 0) {
          if (value.length != 0) {
            let goodsGroupCommonSpec = JSON.parse(value[0].paramTemplate);
            for (let i = 0; i < goodsGroupCommonSpec.length; i++) {
              goodsGroupCommonSpec[i].id = value[0].id;
              goodsGroupCommonSpec[i].value = goodsGroupCommonSpec[i].name;
              goodsGroupCommonSpec[i].children =
                goodsGroupCommonSpec[i].content;
            }
            this.gradeIdOptions = goodsGroupCommonSpec;
          } else {
            this.gradeIdOptions = [];
          }
        } else {
          this.$EL_MESSAGE(message);
        }
      });
    },
    getStorageIdOptions() {
      http.postFront(protocolFwd.param_getStorage).then(response => {
        const { code, message, value } = response.data;
        if (code == 0) {
          this.storageIdOptions = value;
        } else {
          this.$EL_MESSAGE(message);
        }
      });
    },
    calcWeight() {
      let specificationsValue = null;
      for (let i = 0; i < this.specificationsIdOptions.length; i++) {
        if (
          this.form.specificationsId ==
          this.specificationsIdOptions[i].specificationsId
        ) {
          specificationsValue = parseFloat(
            this.specificationsIdOptions[i].value
          );
        }
      }
      this.form.weight = util.floatMul(this.form.quantity, specificationsValue);
    },
    calcZjPrice() {
      this.zjPriceCalc = util.floatMul(this.form.price, this.form.weight);
    },
    handleAvatarSuccess(res, file) {
      this.form.image = res.value;
    },
    beforeAvatarUpload(file) {
      const testmsg = /^image\/(jpeg|png|jpg)$/.test(file.type);
      const isLt2M = file.size / 1024 / 1024 < 2;
      if (!testmsg) {
        this.$EL_MESSAGE.error("上传图片格式不对!");
        return;
      }
      if (!isLt2M) {
        this.$EL_MESSAGE.error("上传图片大小不能超过 2MB!");
      }
      return testmsg && isLt2M;
    },
    // confirm() {
    //   this.$refs["form"].validate(valid => {
    //     if (valid) {
    //       this.dialogVisible1 = true;
    //     }
    //   });
    // },
    confirm() {
      this.$refs["form"].validate(valid => {
        if (valid) {
          let specificationsNames = {}; // 对象
          if (this.form.specificationsName.length != 0) {
            for (let i = 0; i < this.form.specificationsName.length; i++) {
              let currentObj = {};
              let str = this.form.specificationsName[i];
              let str1 = str.split(",");
              currentObj[str1[0]] = str1[1];
              specificationsNames = Object.assign(
                specificationsNames,
                currentObj
              );
            }
          }
          protocolFwd.param_hangSellBaseResources.param.bargainingStatus = this.form.bargainingStatus;
          protocolFwd.param_hangSellBaseResources.param.commodityId = this.newCommodityId;
          protocolFwd.param_hangSellBaseResources.param.specificationsName = specificationsNames
            ? specificationsNames
            : null;
          protocolFwd.param_hangSellBaseResources.param.deliveryTime = this.form.deliveryTime;
          protocolFwd.param_hangSellBaseResources.param.somePriceTime = this.form.somePriceTime;

          protocolFwd.param_hangSellBaseResources.param.quantity = this.form.quantity;
          protocolFwd.param_hangSellBaseResources.param.basisPrice = this.form.basisPrice;
          protocolFwd.param_hangSellBaseResources.param.tradeWay = this.form.tradeWay;
          protocolFwd.param_hangSellBaseResources.param.weight = this.form.weight;
          protocolFwd.param_hangSellBaseResources.param.manufacturer = this.form.manufacturer;
          protocolFwd.param_hangSellBaseResources.param.businessName = this.form.businessName;
          protocolFwd.param_hangSellBaseResources.param.businessPhone = this.form.businessPhone;
          protocolFwd.param_hangSellBaseResources.param.storageId = this.form.storageId;
          protocolFwd.param_hangSellBaseResources.param.sourceId = "M";
          protocolFwd.param_hangSellBaseResources.param.weightUnitId = this.form.weightUnitId;

          const that = this;
          this.isAddLoading = true;
          http
            .postBasisFront(protocolFwd.param_hangSellBaseResources)
            .then(response => {
              const { code, message, value } = response.data;
              this.isAddLoading = false;
              if (code == 0) {
                this.dialogVisible = false;
                this.$EL_MESSAGE("提交成功！");
                this.$refs["form"].resetFields();
                this.$emit("reLoad");
              } else {
                this.$EL_MESSAGE(message);
              }
            })
            .catch(() => {
              this.isAddLoading = false;
            });
        }
      });
    },
    handleClose() {
      this.form = {
        bargainingStatus: null, //是否可以议价T：可以F：不可以
        basisPrice: null, //基差价
        businessName: null, //业务负责人
        businessPhone: null, //
        commodityId: null, //
        deliveryTime: null, //初始交收日
        holdId: null, //持仓id
        manufacturer: null, //厂家
        quantity: null, //挂单数量
        somePriceTime: null, //点价日
        sourceId: null, //来源M：基差挂单F：转让挂单
        specificationsName: [], //
        storageId: null, //仓库id
        tradeWay: null, //A：整批P：分销
        treatyId: 1, //合约id
        weight: null, //挂单重量
        weightUnitId: null //挂单重量单位
      };

      this.selectWeightUnit = {};

      this.$nextTick(() => {
        this.$refs["form"].clearValidate();
      });
      this.dialogVisible = false;
      this.dialogVisible1 = false;
    }
  }
};
</script>
<style lang="scss" scoped>
.tip {
  color: #cf4640;
  position: absolute;
  top: 9px;
  left: 66px;
}
.stepPriceCon {
  line-height: 30px;
}
.stepPriceBtn {
  width: 80px;
  height: 30px;
  line-height: 30px;
  margin: 10px 30px;
  text-align: center;
  border: 1px solid #66b1ff;
  background-color: #66b1ff;
  color: #fff;
}
</style>