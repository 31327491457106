<template>
  <el-dialog
    v-dialogDrag
    title="基差挂单详情"
    :close-on-click-modal="false"
    :visible.sync="dialogVisible"
    :before-close="handleClose"
    width="900px"
  >
    <!-- <div class="auditCon">  
      <p class="auditNote">审核意见：{{ form.note }}</p>
   </div> -->
    <div class="viewDetails">
      <el-row :gutter="20">
        <el-col :span="12">
          <el-row :gutter="20">
            <el-col :span="8">
              <p class="title">商品分类：</p>
            </el-col>
            <el-col :span="16">
              <p class="text">{{ form.className }}</p>
            </el-col>
          </el-row>
        </el-col>
        <el-col
          :span="12"
          v-for="(item, index) of form.specificationsName"
          :key="index"
        >
          <el-row :gutter="20">
            <el-col :span="8">
              <p class="title">商品属性：</p>
            </el-col>
            <el-col :span="16">
              <p class="text">{{ item.label }}</p>
            </el-col>
          </el-row>
        </el-col>

        <el-col :span="12">
          <el-row :gutter="20">
            <el-col :span="8">
              <p class="title">基差价：</p>
            </el-col>
            <el-col :span="16">
              <p class="text">
                {{ form.basisPrice }}元/{{ form.weightUnitName }}
              </p>
            </el-col>
          </el-row>
        </el-col>
        <el-col :span="12">
          <el-row :gutter="20">
            <el-col :span="8">
              <p class="title">数量：</p>
            </el-col>
            <el-col :span="16">
              <p class="text">{{ form.quantity }}</p>
            </el-col>
          </el-row>
        </el-col>
        <el-col :span="12">
          <el-row :gutter="20">
            <el-col :span="8">
              <p class="title">重量：</p>
            </el-col>
            <el-col :span="16">
              <p class="text">{{ form.hangWeight }}{{ form.weightUnitName }}</p>
            </el-col>
          </el-row>
        </el-col>
        <el-col :span="12">
          <el-row :gutter="20">
            <el-col :span="8">
              <p class="title">未摘单重量：</p>
            </el-col>
            <el-col :span="16">
              <p class="text">
                {{ form.unsettledWeight }}{{ form.weightUnitName }}
              </p>
            </el-col>
          </el-row>
        </el-col>

        <el-col :span="12">
          <el-row :gutter="20">
            <el-col :span="8">
              <p class="title">企业名称：</p>
            </el-col>
            <el-col :span="16">
              <p class="text">{{ form.memberEnterpriseName }}</p>
            </el-col>
          </el-row>
        </el-col>
        <!-- <el-col :span="12">
          <el-row :gutter="20">
            <el-col :span="8">
              <p class="title">法人手机号：</p>
            </el-col>
            <el-col :span="16">
              <p class="text">{{ form.enterprisePhone }}</p>
            </el-col>
          </el-row>
        </el-col> -->
        <el-col :span="12">
          <el-row :gutter="20">
            <el-col :span="8">
              <p class="title">状态：</p>
            </el-col>
            <el-col :span="16">
              <p class="text">
                {{ form.hangResourcesStatus | hangStatusFormate }}
              </p>
            </el-col>
          </el-row>
        </el-col>
        <el-col :span="12">
          <el-row :gutter="20">
            <el-col :span="8">
              <p class="title">生产厂家：</p>
            </el-col>
            <el-col :span="16">
              <p class="text">{{ form.manufacturer }}</p>
            </el-col>
          </el-row>
        </el-col>
        <el-col :span="12">
          <el-row :gutter="20">
            <el-col :span="8">
              <p class="title">交收仓库：</p>
            </el-col>
            <el-col :span="16">
              <p class="text">
                {{ form.storageName ? form.storageName : "--" }}
              </p>
            </el-col>
          </el-row>
        </el-col>
        <el-col :span="12">
          <el-row :gutter="20">
            <el-col :span="8">
              <p class="title">业务负责人：</p>
            </el-col>
            <el-col :span="16">
              <p class="text">{{ form.businessName }}</p>
            </el-col>
          </el-row>
        </el-col>
        <el-col :span="12">
          <el-row :gutter="20">
            <el-col :span="8">
              <p class="title">联系电话：</p>
            </el-col>
            <el-col :span="16">
              <p class="text">{{ form.businessPhone }}</p>
            </el-col>
          </el-row>
        </el-col>
        <el-col :span="12">
          <el-row :gutter="20">
            <el-col :span="8">
              <p class="title">最后点价日：</p>
            </el-col>
            <el-col :span="16">
              <p class="text">{{ form.somePriceTime | DateFormateDay }}</p>
            </el-col>
          </el-row>
        </el-col>
        <el-col :span="12">
          <el-row :gutter="20">
            <el-col :span="8">
              <p class="title">初始交收日：</p>
            </el-col>
            <el-col :span="16">
              <p class="text">{{ form.deliveryTime | DateFormateDay }}</p>
            </el-col>
          </el-row>
        </el-col>
        <el-col :span="12">
          <el-row :gutter="20">
            <el-col :span="8">
              <p class="title">是否可议价：</p>
            </el-col>
            <el-col :span="16">
              <p class="text">{{ form.bargainingStatus | isholdFormate }}</p>
            </el-col>
          </el-row>
        </el-col>
        <el-col :span="12">
          <el-row :gutter="20">
            <el-col :span="8">
              <p class="title">交易方式：</p>
            </el-col>
            <el-col :span="16">
              <p class="text">{{ form.tradeWay == "A" ? "整批" : "分销" }}</p>
            </el-col>
          </el-row>
        </el-col>
        <!-- <el-col :span="12">
          <el-row :gutter="20">
            <el-col :span="8">
              <p class="title">图片：</p>
            </el-col>
            <el-col :span="16">
              <img class="proPictureImg" v-if="form.img !== '--'" :src="form.img" />
              <p v-else>--</p>
            </el-col>
          </el-row>
        </el-col> -->
      </el-row>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="dialogVisible = false">取 消</el-button>
      <el-button type="primary" @click="dialogVisible = false">确 定</el-button>
    </span>
  </el-dialog>
</template>
<script>
export default {
  data() {
    return {
      dialogVisible: false,
      form: {},
      priceStep: []
    };
  },
  methods: {
    showDialog(row) {
      this.dialogVisible = true;
      this.getDetails(row.hangResourcesId);
    },
    getDetails(id) {
      let params = {
        auditStatus: null, //	 审核状态Y：待审核T：已通过F：未通过
        className: null, //
        commodityId: null, //
        endTime: null, //
        firmCorporateName: null, // 用户法人名称
        firmEnterpriseName: null, // 用户企业名称
        hangResourceType: null, //挂单类型X：现货挂单 B：履约订金挂单
        hangResourcesId: id, //
        hangResourcesStatus: null, //挂单状态O：已挂单B：部分摘单A：全部摘单U：已撤销
        memberCorporateName: "", //会员法人名称
        memberEnterpriseName: "", //会员企业名称
        memberFirmId: null, //会员id
        page: 0,
        size: 1,
        sourceId: ["M"], //来源M：初始挂单F：转让挂单
        specificationsName: "", //商品属性
        startTime: null, //
        tradeDay: null //交易日
      };
      protocolFwd.param_queryBasisQueryHangResourceLd.param = params;
      http
        .postFront(protocolFwd.param_queryBasisQueryHangResourceLd)
        .then(response => {
          const { code, message, value } = response.data;
          if (code == 0) {
            value.content[0].specificationsName = this.attributeTrans(
              value.content[0].specificationsName
            );
            this.form = value.content[0];
            for (let i in this.form) {
              if (!this.form[i] && this.form[i] != 0) {
                this.form[i] = "--";
              }
            }
          } else {
            this.$EL_MESSAGE(message);
          }
        });
    },
    handleClose() {
      this.dialogVisible = false;
    }
  }
};
</script>
<style scoped>
.viewDetails {
  line-height: 40px;
}
.viewDetails .title {
  text-align: right;
}
.proPictureImg {
  max-width: 80%;
}
.auditCon {
  position: absolute;
  left: 20%;
  top: 20px;
}
.setStyle {
  font-style: normal;
}
</style>
